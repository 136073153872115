<template>
  <section v-if="!close">
    <v-row id="courses">
      <v-col cols="12" lg="3">
        <div class="text-h5 ml-2 mb-5">Courses Overview</div>
        <v-card rounded="lg" elevation="0">
          <v-card-text class="d-flex align-center">
            <div class="">
              <v-icon size="45" color="primary"> mdi-book-outline </v-icon>
            </div>
            <div class="ml-5">
              <div class="caption text-center">TOTAL COURSES</div>
              <div class="display-1">
                {{ courses.length }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" lg="11">
        <v-row no-gutters class="mb-0 pb-0">
          <v-col cols="12" lg="3">
            <search :store="store" :action="action" />
          </v-col>
          <v-col cols="12" lg="3">
            <date :store="store" :action="action" />
          </v-col>
          <!-- <v-col cols="12" lg="3">
            <count :store="store" :action="action" />
          </v-col> -->

          <v-col cols="12" lg="3">
            <v-btn @click="fetch" small class="mt-1 mx-2" color="warning" fab>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn
              @click="compact = !compact"
              small
              class="mt-1"
              color="primary"
              fab
            >
              <v-icon>{{
                !compact ? "mdi-view-grid" : "mdi-format-list-bulleted"
              }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :xl="compact ? 9 : 7" lg="11">
        <v-expand-transition>
          <!-- <v-container fill-height> -->
            <v-row no-gutters class="fill-height" v-if="compact">
              <v-col cols="12" lg="3" 
              v-for="item in courses" 
              :key="item.id" 
              class="pb-5 flex d-flex flex-column">
                <course-card :user="user" :item="item" />
              </v-col>
            </v-row>
            <Table v-else :data="courses" :user="user" />
          <!-- </v-container> -->
        </v-expand-transition>
        <paginate
          v-if="courses.total > 0"
          :store="store"
          collection="courses"
          class="pt-5 col-sm-12 col-lg-8 mx-auto"
        />
        <div v-else class="text-center body-1 mt-5">
          {{ courses ? "" : "No data found" }}
        </div>
      </v-col>
    </v-row>
    <!-- <div v-else class="body-1">Loading...</div> -->
  </section>
  <section v-else>
    <div class="body-1">
      Live Sessions are ongoing!
      <br>
      <br>
      Asynchronous modules are temporarily closed and will open after today’s program. Please proceed to the Meetings Tab. 
      <br><br>
      Thank you!

    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Table from "../components/dashboard/Table.vue";

export default {
  components: {
    Table,
  },
  data: () => ({
    loading: true,
    dialog: false,
    store: "student",
    action: "coursesGetAction",
    snack: false,
    snackText: "",
    vouche: false,
    compact: true,
    close: false
  }),
  computed: {
    ...mapState("student", {
      courses: (state) => state.courses,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("student", ["coursesGetAction"]),
    ...mapMutations("student", ["resetPageMutation"]),

    fetch() {
      this.coursesGetAction({
        page: 0,
        query: "",
        date: "",
        count: 10,
      }).then(() => {
        this.loading = false;
        this.resetPageMutation();
      });
    },
  },
};
</script>